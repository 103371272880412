




























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";

@Component
export default class ActiveDialog extends Request {
  @Prop() readonly type: string;
  @Prop() readonly isSupervisor: string;
  dialog:boolean = false;
  userList = [];
  rooms: any = [];
  roomName: string = "";
  createRoom() {
    this.rooms = {
      name: this.roomName,
      messages: [],
    };
    this.dialog = false;
    this.$emit("loggedUser", this.roomName);
    return this.$emit("rooms", this.rooms);
  }

  contactList = [
    {
      id: 1,
      name: "Ítalo",
      status: "success",
    },
    {
      id: 2,
      name: "Simone",
      status: "gray-light",
    },
    {
      id: 3,
      name: "Leo",
      status: "danger",
    },
    {
      id: 4,
      name: "Danyllo",
      status: "gray-light",
    },
    {
      id: 5,
      name: "Veronica",
      status: "success",
    },
    {
      id: 6,
      name: "Raul Rodrigues",
      status: "success",
    },
  ];
}
