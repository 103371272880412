


























































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
@Component
export default class DeleteQueueWithAttendency extends Request {
  @Prop() entityId: number;
  @Prop() list: any[];
  @Prop() user: any;
  dialog = false;
  path: string;
  breakId: number = null;

  async deleteItems() {
    this.path = this.paths.deleteLogicQueuePath;
    const result = await this.remove(
      this.path +
        this.entityId +
        `?editorUserId=${this.user?.id}&queueToTransfer=${this.breakId} `,
      {}
    );

    this.list.splice(
      this.list.findIndex((value) => value.id == this.entityId),
      1
    );

    this.dialog = false;
    return result;
  }

  get missingBreakId() {
    return !this.breakId;
  }
  get saveDisabled() {
    return this.missingBreakId;
  }
}
