































import { Component } from "vue-property-decorator";
import Request from "../services/request";
// import { translate } from "../plugins/i18n/index";
@Component
export default class PauseButton extends Request {
  itemsPerPage = 1000;
  countAttendency: any = null;
  entryNotification: number = 0;
  async mounted() {
    const listWaiting = await this.get(
      this.paths.listAttendencyPath + `?skip=${1}&take=${this.itemsPerPage}`
    );
    this.countAttendency = listWaiting[0].filter((i) => !i.endDate);
    this.entryNotification = this.countAttendency.length;
    this.updateCount();
  }
  updateCount() {
    setInterval(async () => {
      const listWaiting = await this.get(
        this.paths.listAttendencyPath + `?skip=${1}&take=${this.itemsPerPage}`,null,
        false
      );
      this.countAttendency = listWaiting[0].filter((i) => !i.endDate);
      this.entryNotification = this.countAttendency.length;
    }, 30000);
  }
  get notification() {
    return this.entryNotification;
  }
}
