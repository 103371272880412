



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class ActiveDialog extends Vue {
  dialog: boolean = true;
}
