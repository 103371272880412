







































































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import ConfirmDelete from "../components/ConfirmDelete.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    ConfirmDelete,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class BreaksAgentsDialog extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly loadData: any;
  @Prop() readonly isDisabled: any;
  dialog = false;
  users: any = [];
  userList: any[] = [];
  list: any = [];
  localEntity: any = {};
  editorUserId: number = null;
  getUser: any;
  userAgentsBreaks: any = [];

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async mounted()  {
    this.load();
    const users = await this.get(this.paths.userAgentPath);
    this.users = users[0]
      .filter((item) => item.breaks.find((i) => i.name == this.entity.name))
      .map((e) => {
        return {
          userId: e.userId,
          id: e.id,
          name: e.user.firstName,
          profileId: e.profileId,
        };
      });

    this.list = users[0];
    const user = this.getUser();
    this.editorUserId = user.id;
  }

  async onSave()  {
    if (this.users.length > 0) {
      const user = this.users.map((item) => {
        return {
          userAgentId: item.id,
          breakId: this.localEntity.id,
        };
      });
      this.localEntity.userAgentsBreaks = user;
    }

    this.localEntity.editorUserId = this.editorUserId;
    const result = await this.save(this.paths.userAgentBreakSave, {
      userAgentsBreaks: this.localEntity.userAgentsBreaks,
    });

    this.loadData();
    this.dialog = false;
    return result;
  }

  toggleSelection(item) {
    const index = this.users.findIndex((user) => user.userId === item.userId);
    if (index === -1) {
      this.users.push(item);
    } else {
      this.users.splice(index, 1);
    }
  }

  isSelected(item) {
    return this.users.some((user) => user.userId === item.userId);
  }

  get saveDisabled() {
    return this.missingUsers;
  }
  get missingUsers() {
    return !this.users.length;
  }
}
