













































import {
  Header,
  Sidebar,
  Footer,
  Customizer,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import SideBarItems from "../../utils/SidebarItems";
import { Component } from "vue-property-decorator";
import PauseButton from "../../components/PauseButton.vue";
import CountAttendency from "../../components/CountAttendency.vue";
import { mapGetters } from "vuex";
import Request from "../../services/request";
import { io } from "socket.io-client";
import env from "../../services/env";
import { paths } from "../../services/apiPaths";
import { translate } from "../../plugins/i18n";
@Component({
  components: {
    Header,
    Sidebar,
    Footer,
    Customizer,
    PauseButton,
    CountAttendency,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
      getUserAgent: "general/getUserAgent",
      getSecret: "auth/getSecret",
    }),
  },
})
export default class Layout extends Request {
  // myvar = require("../../../WhatsappButton/index.html");

  SideBarItems = SideBarItems;
  expandOnHover: boolean = false;
  logoIcon = require("../../assets/logo-heppy-white.png");
  logo = require("../../assets/logo-heppy-white.png");
  entity: any = [];
  getUser: any;
  pathLogout = "/";
  user: any = {};
  getUserAgent: any;
  currenctUser: any = {};
  userAgent: any;
  itemsPerPage = 1000;
  source: string;
  pathSaveAccount: string = "";
  pathChangePassword: string = "";
  logoutPah = "/auth/login";
  redirectLink = "www.Heppy.com.br";
  productName = "Heppy";
  countAttendency: any = 0;
  status: any = false;
  socketUrl = `${env.protocol}${env.socket}`;
  socket = io(this.socketUrl, {
    path: `${paths.socketPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  isOpen = false;
  getSecret: any;
  newEntity: any = {};
  logoLight = require("../../assets/logos-new/LOGO 11.png");

  logoDark = require("../../assets/logos-new/LOGO 1.png");
  async loadData() {
    this.userAgent = this.getUserAgent();

    this.userAgent
      ? (this.currenctUser = await this.get(
          this.paths.userAgentPath + this.userAgent
        ))
      : null;
  }

  async mounted() {
    localStorage.setItem("logo-dark", this.logoDark);
    localStorage.setItem("logo-light", this.logoLight);
    this.pathSaveAccount = this.paths.userPath;
    this.pathChangePassword = this.paths.pathChangePassword;
    const user = this.getUser();
    if (user && user.id) {
      this.entity = user;
      const profileId = this.entity?.profile
        ? this.entity.profile.id
        : this.entity.userProfiles.profileId;
      const profile = await this.get(this.paths.profilePath + profileId);
      this.newEntity = { profile: profile, ...this.entity };
    }
    this.loadData();
  }

  created() {
    // this.socket.on("connect", () => {
    //   this.socket.emit("users_online", userName);

    //   this.socket.on("users_online", (status) => {
    //    status ? this.status = true : this.status = false
    //   });
    // });
    this.socket.on("connect", () => {
      this.status = true;
      this.socket.on("disconnect", () => {
        console.log("Um cliente se desconectou.");
      });
    });
  }
  get resolveColor() {
    return this.status === false ? "#FF0000" : "#7DF900";
  }
  get resolveIcon() {
    return this.status === false ? "mdi-lan-disconnect" : "mdi-lan-connect";
  }
  get resolveTitle() {
    return this.status === false
      ? translate("disconect")
      : translate("connect");
  }
}
