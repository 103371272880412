































































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import { format } from "date-fns";
import { mapGetters } from "vuex";
import { translate } from "../plugins/i18n/index";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class CloseDialog extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly loadData: any;
  @Prop() readonly type: string;
  @Prop() readonly isSupervisor: string;
  @Prop() readonly attendency: any;
  localEntity: any = {};
  getUser: any;
  editorUserId: any;
  dialog: boolean = false;
  userAgentId: any;
  queueId: any;
  attendencyId: any;
  endDate: any;
  itemsPerPage: number = 10;
  ratingList = [
    {
      id: 1,
      name: "Encerrado por Inatividade",
      status: "success",
    },
    {
      id: 2,
      name: "Concluído",
      status: "gray-light",
    },
    {
      id: 3,
      name: "Abertura de OS",
      status: "danger",
    },
  ];
  attendencyQueueList: any = [];
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  // async onSave()  {
  //   const today = new Date();
  //   const formattedDate = format(today, "yyyy-MM-dd HH:mm:ss");
  //   const entity = {
  //     id: this.localEntity.id,
  //     userAgentId: this.localEntity.userAgentId,
  //     queueId: this.localEntity.queueId,
  //     attendencyId: this.localEntity.attendencyId,
  //     endDate: formattedDate,
  //     editorUserId: this.editorUserId,
  //     attendencyStatus: 3,
  //   };
  //   this.localEntity.editorUserId = this.editorUserId;
  //   const result = await this.save(
  //     this.paths.attendencyQueuePath,
  //     entity,
  //     null,
  //     translate("closedSuccessfully")
  //   );
  //   
  //   this.dialog = false;
  //   return result;
  // }
  async onSave(conversation)  {
    const attendencyQueueId = this.attendencyQueueList.find(
      (item) => item.attendencyId === conversation.attendencyId
    ).id;

    const today = new Date();
    const formattedDate = format(today, "yyyy-MM-dd HH:mm:ss");

    const result = await this.put(
      this.paths.closureAttendencyPath + attendencyQueueId,
      {
        attendencyStatus: 3,
        userAgentId: conversation.userAgentId,
        editorUserId: this.editorUserId,
        queueId: conversation.queueId,
        attendencyId: conversation.attendency.id,
        endDate: formattedDate,
      }
    );
    // if (result && result.isLastMessage === true) {
    const finalMessage = translate("finalMessageChat");
    this.$emit("end_message", finalMessage);
    this.loadData({ item: this.attendency });
    this.dialog = false;
    return result;
    // } else {
    //   this.loadData();
    //   this.dialog = false;
    //   return result;
    // }
  }
  async mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
    const result = await this.get(
      this.paths.attendencyQueueUserAgent +
        `?userAgentId=${user.userAgent.id}&skip=${1}&take=${this.itemsPerPage}`
    );
    this.attendencyQueueList = result[0];
  }
}
