










































































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import ConfirmDelete from './ConfirmDelete.vue'
import { mapGetters } from "vuex";

@Component({
  components: {
    ConfirmDelete,
  },
  methods: {
    ...mapGetters({ 
      getUser: "auth/getUser",
    }),
  },
})
export default class AssociateEventAgent extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly loadData: any;
  @Prop() readonly isDisabled: any;
  dialog = false;
  users: any = [];
  userList: any[] = [];
  list: any = [];
  localEntity: any = {};
  editorUserId: number = null;
  getUser: any;
 

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async mounted() {
    this.load();
    const users = await this.get(this.paths.userAgentPath);

    this.list = users[0];
    const user = this.getUser();
    this.editorUserId = user.id;
  }

  async onSave()  {
    if (this.users.length > 0) {
      const user = this.users.map((item) => {
        return {
          userId: item.id,
          eventId:this.localEntity.id,
          organizer:1,
        };
      });
 
      this.localEntity.eventParticipants= user;
    }

    this.localEntity.editorUserId = this.editorUserId;
    await this.save(this.paths.eventParticipantsPath, {
     eventParticipants: this.localEntity.eventParticipants,
    });

    this.loadData();
    return (this.dialog = false);
  }

  get saveDisabled() {
    return this.missingUsers;
  }
  get missingUsers() {
    return !this.users.length;
  }
}
