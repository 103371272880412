import Vue from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import router from "../src/services/router";
import store from "../src/store";
import "@mdi/font/css/materialdesignicons.css";
import VueI18n from "vue-i18n"; 
import { i18n } from "./plugins/i18n";
import "@/plugins/base";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
import Rules from "./utils/Rules";
import VueMask from "v-mask";
import { VueMaskDirective } from "v-mask";
import { hasPermission } from "./utils/hasPermission";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueChatScroll from "vue-chat-scroll";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueChatScroll);
import VueCompositionApi, { createApp, h } from "@vue/composition-api";
import { VueQueryPlugin } from "vue-query";
import env from "./services/env";


Vue.use(VueCompositionApi);
Vue.use(VueQueryPlugin); 
Vue.use(VueGoogleMaps, {
  load: {
    key: env.googleMaps,
    libraries: "places",
  },
});
Vue.use(DashboardPlugin);
Vue.use(VueI18n);
Vue.use(VueMask);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.directive("mask", VueMaskDirective);
const eventHub = new Vue();

Vue.prototype.$eventHub = Vue.mixin({
  data: function () {
    return {
      eventHub: eventHub,
    };
  },
});
Vue.directive("has-permission", {
  inserted: function (el, binding, vnode) {
    if (binding === null) {
      el;
    }
    if (!hasPermission(binding.value)) {
      vnode.elm?.parentElement?.removeChild(vnode.elm) ?? false;
    }
  },
});
Vue.config.productionTip = false;
Vue.prototype.$rules = Rules;
Vue.prototype.$vuetify = vuetify;
Vue.use(VueFileAgent);


createApp({
  vuetify,
  store,
  i18n,
  router,
  render() {
    return h(App);
  },
}).mount("#app");