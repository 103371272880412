























import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";

@Component
export default class DetailsRequest extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly list: any[];
  @Prop() readonly loadData: any;

  dialog = false;
  localEntity: any = {};
  listDetails: any = [];

  close() {
    if (!this.entity.id) {
      this.localEntity = {};
    }
    this.dialog = false;
  }
}
