

















































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import { mapGetters } from "vuex";
import { translate } from "../plugins/i18n/index";
import { EventBus } from "../services/eventBus";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
      getUserAgent: "general/getUserAgent",
    }),
  },
})
export default class PauseButton extends Request {
  @Prop() readonly type: string;
  @Prop() readonly entity: any;
  @Prop() readonly isDisabled: any;
  @Prop() readonly user: any;
  @Prop() readonly currenctUser: any;
  @Prop() readonly loadData: any;
  localEntity: any = {};
  getUser: any;
  editorUserId: any;
  dialog: boolean = false;
  getUserAgent: any;
  buttonContent: string = "";
  selectedButton: string = "";
  selectedIcon: string = "mdi-account-alert";
  disableButton: boolean = false;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  selectButton(name) {
    switch (name) {
      case `${translate("initiate")}`:
        this.selectedIcon = "mdi-account-voice";
        this.selectedButton = `${translate("available")}`;
        this.buttonContent = "A";
        this.onSave();
        break;
      case `${translate("pause")}`:
        this.selectedIcon = "mdi-account-clock";
        this.selectedButton = `${translate("paused")}`;
        this.buttonContent = "P";
        this.onSave();
        break;
      case `${translate("shut")}`:
        this.selectedIcon = "mdi-account-cancel";
        this.selectedButton = "Off-line";
        this.buttonContent = "F";
        this.onSave();
        break;
      default:
        break;
    }
  }

  async onSave()  {
    const user = this.getUser();
    const userAgent = this.getUserAgent();
    this.editorUserId = user.id;

    const result = await this.save(
      this.paths.queueUserAgentLogout,
      {
        userAgentId: userAgent,
        status: this.buttonContent,
      },
      null,
      `${translate("StatusChanged")}`
    );

    EventBus.$emit("update-chat", this.buttonContent);

    this.loadData();
    this.dialog = false;
    return result;
  }

  mounted() {
    const user = this.getUser();
    this.load();

    setTimeout(async () => {
      const validStatus = await this.get(
        this.paths.queueUserAgentStatus + user.userAgent.id,
        null,
        false
      );

      if (validStatus.length == 0) {
        return (this.disableButton = true);
      }
      let status;
      const currenctStatus = validStatus[0];
      if (validStatus && currenctStatus[0] && currenctStatus[0].status) {
        status = currenctStatus[0].status;
      } else {
        status = "F";
      }

      if (status === "A") {
        this.selectedButton = `${translate("available")}`;
        this.selectedIcon = "mdi-account-voice";
      } else if (status === "P") {
        this.selectedButton = `${translate("paused")}`;
        this.selectedIcon = "mdi-account-clock";
      } else if (status === "F") {
        this.selectedButton = "Off-line";
        this.selectedIcon = "mdi-account-cancel";
      } else {
        this.selectedButton = "";
        this.selectedIcon = "mdi-account-alert";
      }
    }, 1000);
  }

  badgeColor() {
    // Definir a cor do v-icon
    switch (this.selectedButton) {
      case `${translate("available")}`:
        return "#7DF900";
      case `${translate("paused")}`:
        return "#FFD700";
      case "Off-line":
        return "#CD5C5C";
      default:
        return "#F0F8FF";
    }
  }

  created() {
    EventBus.$on("status-updated", (status) => {
      if (status == "F") {
        this.selectedButton = "Off-line";
        this.selectedIcon = "mdi-account-cancel";
      }
    });
  }
}
