
















































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import { mapGetters } from "vuex";
import { translate } from "../plugins/i18n/index";
import { EventBus } from "../services/eventBus";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class LogoutDialog extends Request {
  @Prop() readonly type: string;
  @Prop() readonly isSupervisor: string;
  @Prop() readonly entity: any;
  @Prop() readonly loadData: any;
  @Prop() readonly isDisabled: any;
  localEntity: any = {};
  getUser: any;
  editorUserId: any;
  dialog: boolean = false;
  userAgentId: any;
  attendencyLimit: any;
  status: any;

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async onSave()  {
    this.localEntity.editorUserId = this.editorUserId;
    const entity = {
      userAgentId: this.localEntity.id,
      status: "F",
    };
    const result = await this.save(
      this.paths.queueUserAgentLogout,
      entity,
      null,
      translate("successfullyLoggedout")
    );
    if (result) {
      EventBus.$emit("status-updated", entity.status);
    }
    this.loadData();
    this.dialog = false;
    return result;
  }
  async mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
  }
}
