






























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
import { format } from "date-fns";
import { translate } from "../plugins/i18n";
import { mapGetters } from "vuex";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class BreaksDialog extends Request {
  @Prop() entity: any;
  @Prop() loadData: any;
  @Prop() type: boolean;
  @Prop() breaks: any;
  @Prop() isDisabled: any;

  isActive = true;
  dialog = false;
  users: any = [];
  userList: any[] = [];
  list: any = [];
  localEntity: any = {};
  editorUserId: number = null;
  getUser: any;
  userAgentBreakId: any;
  breakId: number = null;
  status: string = "";
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };

      if (this.localEntity?.queueUserAgents[0]?.status == "A") {
        this.status = "P";
      } else {
        this.status = "A";
      }
    }
  }

  async onSave()  {
    const today = new Date();
    const formattedDate = format(today, "yyyy-MM-dd HH:mm:ss");

    const user = this.getUser();
    this.editorUserId = user.id;
    if (this.localEntity?.queueUserAgents[0]?.status == "A") {
      const result = await this.save(
        this.paths.userAgentBreakReport,
        {
          userAgentId: this.localEntity.userId,
          breakId: this.breakId,
          startedAt: formattedDate,
        },
        null,
        `${translate("StatusChanged")}`
      );

      this.loadData();
      this.load();
      this.dialog = false;
      return result;
    } else {
      this.localEntity.editorUserId = this.editorUserId;
      const result = await this.save(this.paths.userAgentBreakReport, {
        userAgentId: this.localEntity.userId,
        startedAt: formattedDate,
        endAt: formattedDate,
        breakId: this.localEntity.id,
      });
      this.loadData();
      this.dialog = false;
      return result;
    }
  }

  async mounted() {
    this.load();

    //const userAgentBreak = await this.get(this.paths.userAgentBreak);
    //return userAgentBreak
  }
  resolveTitle() {
    return this.entity?.queueUserAgents[0]?.status == "A"
      ? translate("breakAttendency")
      : translate("unpauseAttendency");
  }
  resolveIconTitle() {
    return this.entity?.queueUserAgents[0]?.status == "A"
      ? translate("pause")
      : translate("unpause");
  }
  get isCardActive() {
    return this.entity?.queueUserAgents[0]?.status == "A" ? true : false;
  }
}
