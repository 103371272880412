





































import { Component, Prop } from "vue-property-decorator";
import Request from "../services/request";
@Component
export default class DeleteWithChildren extends Request {
  @Prop() path: string;
  @Prop() entityId: number;
  @Prop() list: any[];
  @Prop() haveUser: any;
  @Prop() messageDelete: string;
  dialog = false;
  newPaths: string;

  beforeMount() {
    this.newPaths = this.path;
  }

  async deleteItems() {
    await this.remove(this.newPaths + this.entityId, {});

    this.list.splice(
      this.list.findIndex((value) => value.id == this.entityId),
      1
    );
    this.dialog = false;
  }
}
